.App {  
  padding: 10px;
  padding-left: 0px;
  padding-bottom: 20px;
  text-align: left;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 300;
}

.immoStepTitle
{
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1.75rem;
  color: #4184CD;
  font-weight: 700;
}

.immoButton
{
  font: inherit;
  width: 200px;
  height: 100px;
  border: none;
  background-color: gray;
  color: white;
  padding-top:65px; 
  margin-bottom: 5px;
  margin-right: 5px;  
}

.immoButton-Selected
{
  font: inherit;
  width: 200px;
  height: 100px;
  border: none;  
  color: white;
  background: #003DA5; 
  padding-top:65px;
  margin-bottom: 5px;
  margin-right: 5px;  
}

#immoButton-Einfamilienhaus
{
  background-size: 55px;
  background-image: url("./images/einfamilienhaus.png");  
  background-position: center;
  background-position-y: 10px;
  background-repeat: no-repeat;
}

#immoButton-Wohnung
{
  background-size: 45px;
  background-image: url("./images/wohnung.png");  
  background-position: center;
  background-position-y: 10px;
  background-repeat: no-repeat;
}

#immoButton-Gewerbeobjekt
{
  background-size: 70px;
  background-image: url("./images/gewerbeobjekt.png");  
  background-position: center;
  background-position-y: 10px;
  background-repeat: no-repeat;
}

#immoButton-HausFreistehend
{
  background-size:55px;
  background-image: url("./images/haus.png");  
  background-position: center;
  background-position-y: 10px;
  background-repeat: no-repeat;
}

#immoButton-HausDoppelhaus
{
  background-size: 55px;
  background-image: url("./images/doppelhaus.png");  
  background-position: center;
  background-position-y: 10px;
  background-repeat: no-repeat;
}

#immoButton-HausReihenhaus
{
  background-size: 85px;
  background-image: url("./images/reihenhaus.png");  
  background-position: center;
  background-position-y: 10px;
  background-repeat: no-repeat;
}

#immoButton-WohnungErdgeschoss
{
  background-size:75px;
  background-image: url("./images/eg.png");  
  background-position: center;
  background-position-y: 5px;
  background-repeat: no-repeat;
}

#immoButton-WohnungObergeschoss
{
  background-size: 75px;
  background-image: url("./images/og.png");  
  background-position: center;
  background-position-y: 5px;
  background-repeat: no-repeat;
}

#immoButton-WohnungDachgeschoss
{
  background-size: 75px;
  background-image: url("./images/dg.png");  
  background-position: center;
  background-position-y: 5px;
  background-repeat: no-repeat;
}

#immoButton-GewerbeobjektGeschaeftslokal
{
  background-size: 55px;
  background-image: url("./images/geschaeftslokal.png");  
  background-position: center;
  background-position-y: 10px;
  background-repeat: no-repeat;
}

#immoButton-GewerbeobjektGewerbehaus
{
  background-size: 55px;
  background-image: url("./images/gewerbehaus.png");  
  background-position: center;
  background-position-y: 10px;
  background-repeat: no-repeat;
}

#immoButton-GewerbeobjektGebaeudekomplex
{
  background-size: 55px;
  background-image: url("./images/gebaeudekomplex.png");  
  background-position: center;
  background-position-y: 10px;
  background-repeat: no-repeat;
}

.immoSlider
{
  width: 400px;
  text-align: center; 
  margin-bottom: 10px; 
}

.immoAdresse
{
  width: 400px;
  font: inherit;
  margin-bottom:10px;
}

.immoSelect {
  margin-bottom: 10px;
}

.immoSelect label
{
  margin-right: 10px;
}

.immoBackButton
{  
  margin-top: 10px;  
  margin-left: 0px;
  margin-right: 15px;    
  font: inherit;
}

.immoSubmitButton
{  
  margin-top: 10px;  
  margin-left: 0px;
  margin-right: 5px;    
  font: inherit;
  font-size: 1rem;
  font-weight: 400;
  color: white;
  border-radius: 5px;
  background-color: #003DA5;
  cursor: pointer;
  padding: 10px 20px;
  height:50px;
  justify-content: space-evenly;
  min-width: 200px;
  display: inline-flex;
  text-align: center;
  vertical-align: middle;
}